import { render, staticRenderFns } from "./InstoStore.vue?vue&type=template&id=62c22c8c&scoped=true"
import script from "./InstoStore.vue?vue&type=script&lang=js"
export * from "./InstoStore.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c22c8c",
  null
  
)

export default component.exports